<template>
  <div id ="app">
    <Header></Header>
    <Intro></Intro>
    <Projects></Projects>
    <Contact></Contact>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "./components/Header.vue";
import Intro from "./components/Intro.vue";
import Projects from "./components/Projects.vue";
import Contact from "./components/Contact.vue";
import Footer from './components/Footer.vue';

export default {
  name: "App",
  created(){
    document.title = "Loladoon - FrontEnd Web Developer"
  },
  components: {
    Header,
    Intro,
    Projects,
    Contact,
    Footer
  }
}
</script>

<style lang="scss">
  @import "./scss/variables.scss";
  @import "./scss/mixins.scss";
  body {
    margin: 0;
    padding: 0;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color:$background;
    color: $text;
    line-height: 1.6;
    #app {
      // max-width:960px;
      max-width: 1024px;
      margin:0px auto;
      // padding: 20px;
      padding:16px;
    }
    hr {
      border:solid lighten($text, 65%); 
      border-width:1px 0 0; 
      margin:0;
    }
  }
  // X-Small devices (portrait phones, 960px or less)
  @media (max-width: 960px) {
    body #app {
      max-width:600px;
    }
  }
  // X-Small devices (portrait phones, less than 576px)
  @media (max-width: 575.98px) {
    body #app {
      margin:6px auto 0;
      padding:10px 0px 0; //10px 20px 0
      max-width:96%;
    }
  }
</style>
