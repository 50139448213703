<template>
  <div id="intro">
    <div class="intro-container">
      <div class="col1">
        <div class="imgContainer">
          <div class="hexIndent">
            <div class="hexa-border"></div>
            <div class="hexagon hexa">
              <div class="hexagon-in">
                <img :src="`${profileImg}`" class="profileImg" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col2">
        <h1>{{ intro }}</h1>
        <h2>{{ subIntro }}</h2>
        <h3>{{ aboutIntro }}</h3>
      </div>
    </div>
  </div>
  <hr/>
</template>

<script>
import profileImg from "../assets/2022_me-1.jpg";
  export default {
    name: "Intro",

    data() {
      return {
        profileImg,
        intro: "Hey There, I'm Lauren.",
        subIntro: "Frontend Web Developer.",
        // aboutIntro: "I have a passion for building sleek & intuitive web applications from Chicago, Illinois.",
        aboutIntro: "Passionate about building sleek & intuitive web applications; from Chicago, Illinois.",

      }
    },
  }
</script>

<style lang="scss">
  @import "../scss/variables.scss";
  @import "../scss/mixins.scss";
  @import "../scss/hexagons.scss";
  #intro {
    padding-bottom:36px; //80px padding-bottom temp for HR spacing
    margin:0 auto;
    .intro-container {
      display:flex;
      flex-direction:row;
      align-items: center;
      padding:8px 0;
      .col1, .col2 {
        display:inline-flex;
        flex-direction: column;
        width:auto;
      }
      .col1 {
        // width:25%;
        // max-width:202px;
        max-width:25%;
        height:100%;
        margin:8px auto 0;
        padding-left:8px;
      }
      .col2 {
        width:75%;
        max-width: 653px; // or 637px w/o margin set
        margin:0 0 0 auto;
      }
      h1, h2, h3 {
        @include textAlignment();
        margin:0;
      }
      h1 {
        @include mainText();
        color:$accent;
        font-size:$size6;
      }
      h2, h3 {
        @include defaultText();
      }
      h2 {
        font-size:32px;
        font-weight:600;
      }
      h3{
        font-size:28px;
        font-weight:300;
      }
    }
  }

  // X-Small devices (portrait phones, 960px or less)
  @media (max-width: 960px) {
    #intro {
      max-width:600px;
      padding:0 0 60px;
      .intro-container {
        display:grid;
        padding:0;
        width:100%;
        margin:0 auto;
        .col1, .col2 {
          width:auto;
          min-width:200px;
          text-align: center;
          display:grid;
          max-width:100%;
          height:auto;
          margin:0 auto;
          padding:0;
        }
        .col1 {
          padding-bottom:40px;
        }
        .col2 {
          h3 {
            font-size:$size4;
          }
        }
      }
    }
  }
  // X-Small devices (portrait phones, less than 576px)
  @media (max-width: 575.98px) {
    #intro {
      .intro-container {
        h1 {
          font-size:38px;
        }
        h2 {
          font-size:28px;
        }
        h3 {
          font-size:22px;
          padding:0 16px;
        }
      }
    }
  }
</style>