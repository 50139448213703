<template>
  <div id="header">
    <a :href="`${url}`">{{ header }}</a>
  </div>
</template>

<script>
  export default {
    name: "Header",

    data() {
      return {
        header: "Loladoon.",
        url: "http://loladoon.com",
      }
    },
  }
</script>

<style lang="scss">
  @import "../scss/variables.scss";
  @import "../scss/mixins.scss";
  #header {
    text-align:center;
    padding-bottom:60px;
    a {
      @include textAlignment();
      @include mainText();
      color:$accent;
      // font-weight: bold;
      // font-size: 28px;
      font-size:20px;
      text-align: center;
      margin-bottom:30px;
      text-decoration:none;
      text-transform:lowercase;
    }
  }
  // X-Small devices (portrait phones, 960px or less)
  @media (max-width: 960px) {
    #header {
      padding-bottom:30px;
    }
  }
</style>