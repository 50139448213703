<template>
  <div id="projects">
    <h1 class="indent">{{ projects }}</h1>
    <div class="proj-container">
      <div class="col1">
        <!-- <p>{{ projectsInfo }}<span class="code-item">{{ codingLang }}</span></p> -->
        <p class="proj-text">{{ projectsInfo }} 
          <!-- <span v-for="(code, index, name) in codes" :key="index" :name="name" class="code"> -->
          <!-- <span v-text="code.text" :name="code.name" class="code-item"></span> -->
          <!-- <span class="code-item">{{ code.name }}</span> -->
          <!-- <span v-text="code.text" :name="code.name" class="code-item"></span> -->
        <!-- </span></p> -->
          <span @mouseover="setActiveContext('html')" @mouseleave="setActiveContext(null)" class="code-item" name="html">HTML/CSS, </span> 
          <span @mouseover="setActiveContext('sass')" @mouseleave="setActiveContext(null)" class="code-item" name="sass">Sass, </span> 
          <span @mouseover="setActiveContext('vanJS')" @mouseleave="setActiveContext(null)" class="code-item" name="vanJS">Vanilla JS, </span> 
          <span @mouseover="setActiveContext('jQuery')" @mouseleave="setActiveContext(null)" class="code-item" name="jQuery">jQuery, </span> 
          <span @mouseover="setActiveContext('react')" @mouseleave="setActiveContext(null)" class="code-item" name="react">React </span> 
          <span class="code-item">& </span>
          <span @mouseover="setActiveContext('vue')" @mouseleave="setActiveContext(null)" class="code-item" name="vue">Vue.</span>
        </p>
        <p class="proj-text">{{ requestText }}</p>
      </div>
      <div class="col2">
        <div class="box">
          <ul class="box-items one">
            <!-- <li v-for="(link, index, getClass) in linksOne" :key="index" :class="getClass">  -->
              <!-- :class="getClasses(['html'])" -->
            <!-- <li v-for="(link, index) in linksOne" :key="index" :class="getClass(['html', 'vue'])">
              <a v-text="link.text" :href="link.url" :class="getClass" target="_blank"></a>
            </li> -->
            <li :class="getClass(['html', 'sass', 'react'])">
              <a href="http://loladoon.com/projects/memoryMatch/" target="_blank" text="Match App"></a>
            </li>
            <!-- <li :class="getClass(['html', 'sass', 'vue'])">
              <a href="http://loladoon.com/projects/todo-app/" target="_blank" text="ToDo App"></a>
            </li> -->
            <li :class="getClass(['html', 'sass', 'vue'])">
              <a href="http://loladoon.com/projects/mg-app/" target="_blank" text="Pasta App"></a>
            </li>
            <li :class="getClass(['html', 'sass', 'vanJS'])">
              <a href="http://loladoon.com/projects/madlibs/" target="_blank" text="Madlibs"></a>
            </li>
             
          </ul>
          <ul class="box-items two">
            <!-- <li v-for="(link, index) in linksTwo" :key="index" :class="[link.getClass, 'caption']">
              <a v-text="link.text" :href="link.url" :class="link.getClass" target="_blank"></a>
            </li> -->
            <li :class="getClass(['html', 'sass', 'vue'])">
              <a href="http://loladoon.com/projects/8-ball/" target="_blank" text="Magic 8Ball"></a>
            </li>
            <li :class="getClass(['html', 'sass', 'jQuery'])">
              <a href="http://loladoon.com/projects/mathematical/" target="_blank" text="Math App"></a>
            </li>
            <li :class="getClass(['html', 'sass', 'vue'])">
              <a href="http://loladoon.com/projects/recipe-app/" target="_blank" text="Recipe App"></a>
            </li>
            <li :class="getClass(['html', 'sass', 'react'])">
              <a href="http://loladoon.com/projects/anagram/" target="_blank" text="Anagrams"></a>
            </li>
            <!-- <li :class="getClass(['html', 'vue'])">
              <a href="" target="_blank" text=""></a>
            </li> -->
          </ul>
          <ul class="box-items three">
            <!-- <li v-for="(link, index) in linksThree" :key="index" :class="[link.getClass, 'caption']">
              <a v-text="link.text" :href="link.url" :class="link.getClass" target="_blank"></a>
            </li> -->
            <li :class="getClass(['html', 'sass', 'vue'])">
              <a href="http://loladoon.com/projects/calculator" target="_blank" text="Calc App"></a>
            </li>
            <li :class="getClass(['html', 'sass', 'vue'])">
              <a href="http://loladoon.com/projects/bmi/" target="_blank" text="BMI Calc"></a>
            </li>
            <li :class="getClass(['html', 'sass', 'react'])">
              <a href="http://loladoon.com/projects/tictactoe/" target="_blank" text="TicTacToe"></a>
            </li>
            <!-- <li :class="getClass(['html', 'sass', 'vue'])">
              <a href="http://loladoon.com/projects/budget-app/" target="_blank" text="Budget App"></a>
            </li> -->
            
          </ul>
          <ul class="box-items four">
            <!-- <li :class="getClass(['html', 'sass', 'vue'])">
              <a href="http://loladoon.com/projects/calculator" target="_blank" text="Calc App"></a>
            </li> -->
            <!-- <li :class="getClass(['html', 'sass', 'vue'])">
              <a href="http://loladoon.com/projects/mg-app/" target="_blank" text="Pasta App"></a>
            </li> -->
            <!-- <li :class="getClass(['html', 'sass', 'vue'])">
              <a href="" target="_blank" text=""></a>
            </li>
            <li :class="getClass(['html', 'sass', 'vue'])">
              <a href="" target="_blank" text=""></a>
            </li> -->
          </ul>
        </div>
      </div>
    </div>
  </div>
  <hr />
</template>

<script>
  export default {
    name: "Projects",

    data() {
      return {
        projects: "Projects.",
        projectsInfo: "Featured selections of applications I've built from the ground up utilizing; ",
        activeContext: null,
        // codingLang: "HTML/CSS, Sass, Vanilla JS, jQuery, React & Vue.",
        // codes: [
        //   { text: "HTML/CSS, ", name: "html" },
        //   { text: "Sass, ", name: "sass" },
        //   { text: "Vanilla JS, ", name: "vanJS" },
        //   { text: "jQuery, ", name: "jQuery" },
        //   { text: "React, ", name: "react" },
        //   { text: "& " },
        //   { text: "Vue", name: "vue" },
        //   { text: "."},
        // ],
        requestText: "Other examples are available upon request.",
        // isActive:false,
        // isMute: false,
        // linksOne: [
        //   { text: "BMI Calc", url: "http://loladoon.com/projects/bmi/", getClass:(["html", "vue", "sass"]) },
        //   { text: "ToDo App", url: "http://loladoon.com/projects/todo-app/", getClass:(["html", "vue"]) }, 
        //   { text: "Budget App", url: "http://loladoon.com/projects/budget-app/", getClass:(["html", "vue"]) },
        // ],
        // linksTwo: [
        //   { text: "Magic 8Ball", url: "http://loladoon.com/projects/8-ball/", getClass:["html", "vue"] },
        //   { text: "Calc App", url: "http://loladoon.com/projects/calculator", getClass:["html", "vue"] },
        //   { text: "Recipe App", url: "http://loladoon.com/projects/recipe-app/", getClass:["html", "vue"] },
        //   { text: "Match App", url: "http://loladoon.com/projects/memoryMatch/", getClass:["html", "react"] },
        // ],
        // linksThree: [
        //   { text: "Anagrams", url: "http://loladoon.com/projects/anagram/", getClass:["html", "react"] },
        //   { text: "TicTacToe", url: "http://loladoon.com/projects/tictactoe/", getClass:["html", "react"] },
        //   { text: "Madlibs", url: "http://loladoon.com/projects/malibs/", getClass:["html", "vanJS"] },
        // ],
          // { text: "", url: "http://loladoon.com/projects/" },
          // { text: "", url: "http://loladoon.com/projects/" },
          // { text: "", url: "http://loladoon.com/projects/" },
          // { text: "", url: "http://loladoon.com/projects/" },
      }
    },
    methods: {
      setActiveContext(value = null) {
        this.activeContext = value;
      },
      // getClasses(keys) {
        getClass(keys) {
        // Return an empty object if activeContext is null
        if (!this.activeContext) return {};
        // Check if the activeContext exists in the keys argument
        const activeContextIncludedInKeys = keys.includes(this.activeContext);
        // Return 'active' class if it's included, 'mute' if it is not
        return {
          'active': activeContextIncludedInKeys,
          // isActive: true,
          'mute': !activeContextIncludedInKeys,
          // isMute: !true,
        }
      }
    },
    // methods: {
    //   linkClass() {
    //     const spans = $(".col1 p .code").find(".code-item");
    //     spans.hover(
    //       if(code.name == link.name) {
    //         this.isActive = true;
    //         this.isMute = false;
    //       } else {
    //         this.isActive = false;
    //         this.isMute = true;
    //       }
    //     )
    //   }
    // },
  }
</script>

<style lang="scss">
  @import "../scss/variables.scss";
  @import "../scss/mixins.scss";
  @import "../scss/hexagons.scss";
    #projects {
      max-width:1024px;
      margin:0 auto;
      // padding-bottom:36px;
      h1.indent {
        @include mainText();
        @include textAlignment();
        color:$accent;
        font-size:$size6;
        padding:20px 32px 0;
      }
      .proj-container {
        display: flex;
        // flex-direction:row;
        padding-left:32px;
        width:100%;
        .col1 {
          max-width:450px;
          width:45%;
          display:inline-flex;
          flex-direction: column;
          p {
            @include defaultText();
            letter-spacing:0;
            font-weight:300;
            font-size:$size4;
            
            .code-item{
              font-weight:600;
            }
          }
          p span.code-item[name="html"]:hover,
          p span.code-item[name="sass"]:hover,
          p span.code-item[name="vanJS"]:hover,
          p span.code-item[name="jQuery"]:hover,
          p span.code-item[name="react"]:hover,
          p span.code-item[name="vue"]:hover
           {
            color:$accent;
            cursor:default;
          }
        }
        .col2 {
          width:55%;
          display:inline-flex;
          flex-direction:column;
          // align-items:center;
          max-width:505px;
          margin:0px auto; //-50px auto 0
          height:405px; //505px
          .box {
            display:inline-flex;
            flex-direction:column;
            align-items:center;
            ul.box-items {
              li {
                justify-content:center;
                align-items: center;
                a {
                  @include styleRemoval();
                  @include hexagonText();
                }
              }
            }
            // ul.box-items.one {
            //   margin-left:-100px;
            // }
            ul.box-items.two {
              // top: -54px;
              margin-top:-38px;
            }
            ul.box-items.three {
              // top:-109px;
              margin-top:-38px;
              // margin-left:-100px;
            }
            ul.box-items.four {
              // top:-163px;
              margin-top:-38px;
            }
            ul.box-items li[name="html"].active,
            ul.box-items li[name="sass"].active,
            ul.box-items li[name="vanJS"].active,
            ul.box-items li[name="jQuery"].active,
            ul.box-items li[name="react"].active,
            ul.box-items li[name="vue"].active,
            ul.box-items li.active {
              opacity:1;
              @include hexagon(95px, $accent, $border:0);
              margin:27.36px 3px;
              cursor:pointer;
            }
            ul.box-items li[name="html"].mute,
            ul.box-items li[name="sass"].mute,
            ul.box-items li[name="vanJS"].mute,
            ul.box-items li[name="jQuery"].mute,
            ul.box-items li[name="react"].mute,
            ul.box-items li[name="vue"].mute,
            ul.box-items li.mute {
              opacity:0.4;
            }
          }
        }
      }
    }

  // X-Small devices (portrait phones, 960px or less)
  @media (max-width: 960px) {
    #projects {
      max-width:600px;
      padding:0 0 60px;
      h1.indent {
        text-align: center;
        padding:36px 0 0;
        margin:0;
      }
      .proj-container {
        display:grid;
        padding:0;
        width:100%;
        margin:0 auto;

        .col1, .col2 {
          width:100%;
          text-align: center;
          display:grid;
          max-width:100%;
          height:auto;
          margin:0 auto;
          padding:0;
        }
        .col1 {
          p.proj-text, p.code-item {
            // font-size:22px;
            line-height:1.25;
          }
        }
        .col2 .box .box-items {
          padding:0;
        }
      }
    }
  }
  // X-Small devices (portrait phones, less than 576px)
  @media (max-width: 575.98px) {
    #projects {
      h1.indent {
        padding:48px 0 0;
        font-size:38px;
      }
      .proj-container {
        .col1 {
          p.proj-text, p.code-item {
            font-size:22px;
            // line-height:1.25;
            padding: 0 16px;
          }
          // p.code-item{
          //   font-size:22px;
          // }
        }
      }
    }
  }
  </style>