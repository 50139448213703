<template>
  <div id="contact">
    <h1 class="indent">{{ contact }}</h1>
    <div class="contact-container">
      <div class="contact-text">
        <p>{{ contactInfo }}</p>
      </div>
      <div class="contact-btns">
        <span class="email">
          <a href="mailto:loladoon@icloud.com?Subject=New Project">
            <img :src="`${emailIcon}`" class="emailIcon" />
          </a>
        </span>
        <span class="linked">
          <a href="https://www.linkedin.com/in/laurenelliottmanheim" target="_blank">
            <img :src="`${linkedIcon}`" class="linkedIcon" />
          </a>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import emailIcon from "../assets/email.png";
import linkedIcon from "../assets/linked.png";
  export default {
    name: "Contact",

    data() {
      return {
        contact: "Contact.",
        contactInfo: "Intrigued? Think I'd be a great fit for your team or project? Feel free to send an email, if you're interested in working with me or discussing the Prime Directive.",
        emailIcon,
        linkedIcon,
      }
    },
  }
</script>

<style lang="scss">
  @import "../scss/variables.scss";
  @import "../scss/mixins.scss";
  @import "../scss/hexagons.scss";
  #contact {
    // max-width:1024px;
    width:100%;
    margin:0 auto;
    padding:0 0 48px;
    h1.indent {
      @include mainText();
      @include textAlignment();
      color:$accent;
      font-size:$size6;
      padding:20px 32px 0;
      margin-bottom:10px;
    }
    .contact-container {
      display:grid;
      // flex-direction:row;
      align-items: center;
      padding:8px 0;
      .contact-text {
        display:flex;
        width:auto;
        max-width:800px;
        margin:0 auto;
        p {
          @include defaultText();
          letter-spacing:0;
          font-weight:300;
          font-size:$size4;
          // max-width:800px;
          // margin:0 auto;
          text-align: center;
        }
      }
      .contact-btns {
        display:flex;
        width:auto;
        // margin:0 auto;
        justify-content:center;
        padding:16px;
        span {
          padding:0 8px;
        }
        .email img, .linked img {
          width:35px;
          height:auto;
        }
      }
    }
  }

  // X-Small devices (portrait phones, 960px or less)
  @media (max-width: 960px) {
    #contact {
      max-width:600px;
      padding:0 0 20px; //60px
      h1.indent {
        text-align: center;
        padding:36px 0 0;
        margin:0;
      }
      .contact-container {
        .contact-text {
          p {
            line-height:1.25;
          }
        }
        .contact-btns {
          .email img,
          .linked img {
            width:40px;
          }
        }
      }
    }
  }

  // X-Small devices (portrait phones, less than 576px)
  @media (max-width: 575.98px) {
    #contact {
      padding:0;
      h1.indent {
        font-size:38px;
        padding:48px 0 0;
      }
      .contact-container {
        padding:8px 12px;
        .contact-text {
          p {
            font-size:22px;
            // line-height:1.25;
          }
        }
        .contact-btns {
          .email img, 
          .linked img {
            width:45px;
          }
        }
      }
    }
  }
</style>
