<template>
  <div id="footer">
    <h6>{{ footer }}</h6>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      footer: "Lauren Elliott-Manheim • 2015-2023 • Ex astris, scientia"
    }
  },
}
</script>
<style lang="scss">
  @import "../scss/variables.scss";
  @import "../scss/mixins.scss";
  #footer {
    padding:0;
    margin:0;
    h6 {
      @include defaultText();
      color:$accent;
      font-weight:400;
      letter-spacing:2.25px;
      text-transform:uppercase;
      padding:0 16px;
      margin:0;
      text-align:center;
      line-height:1.25;
    }
  }
  // X-Small devices (portrait phones, less than 576px)
  @media (max-width: 575.98px) {
    #footer {
      h6 {
        // padding-bottom:0;
        margin-bottom:0;
        letter-spacing:0.69px; //Niiice
        padding:24px 0 16px;
      }
    }
  }
</style>